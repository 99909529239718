import type { LenderType } from './lenders';
import type { AllowedRegionCode } from 'utils/rate-filters';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L214
 *
 * // DB model not marshal into JSON format
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L812
 */
export type Product = {
    rateBase?: number;
    readonly id: number;
    name: string;
    family: string;
    type: ProductType;
    term: ProductTerm;
    insurable: boolean;
    insurance: ProductInsurance;
    purpose: ProductPurpose;
    /** @deprecated use `amortizationMax`, remove optional when removing deprecated */
    amortization: number;
    amortizationMax?: number;
    ltvMin: number;
    ltvMax: number;
    /** @deprecated use `tdsMax`, remove optional when removing deprecated */
    tds: number;
    tdsMax?: number;
    /** @deprecated use `gdsMax`, remove optional when removing deprecated */
    gds: number;
    gdsMax?: number;
    /** @deprecated use `propertyValueMax`, remove optional when removing deprecated */
    maxPropertyValue: number;
    propertyValueMax?: number;
    /** @deprecated use `mortgageAmountMax`, remove optional when removing deprecated */
    maxMortgageAmount: number;
    mortgageAmountMax?: number;
    /** @deprecated Deleted */
    rentalIncomeType: ProductRentalIncompeType;
    /** @deprecated Deleted */
    rentalIncomeValue: number;
    creditScoreMin: number;
    creditScoreMax: number;
    prepaymentOption: ProductPrepaymentOption;
    prepaymentDoubleOption: string;
    prepaymentLumpSumOption: string;
    prepaymentIncreaseOption: string;
    paymentOptionWeekly: boolean;
    paymentOptionWeeklyAccelerated: boolean;
    paymentOptionBiWeekly: boolean;
    paymentOptionBiWeeklyAccelerated: boolean;
    paymentOptionSemiMonthly: boolean;
    paymentOptionMonthly: boolean;
    restrictionsOption: ProductRestrictionOption;
    restrictions: string;
    fixedPenaltySpread: string;
    helocOption: ProductHELOCOption;
    helocDelta: number;
    lenderId: number;
    lenderName: string;
    lenderType: LenderType;
    rateHold: ProductRateHold;
    /** @deprecated Deleted */
    baseRate: number;
    rate: number;
    ratePrimeVariance: number;
    /** @deprecated Deleted */
    rateProductVariance: number;
    bestRate: number;
    commissionBPS: number;
    presentationPdfUrlsByLanguage: Record<string, string>;
    created: string; // TODO evole to Date;
    updated: string; // TODO evole to Date;
    regionCode?: AllowedRegionCode;
    mortgageAmountMin?: number;
    externalId?: string;
};

export type ProductTerm =
    | '6_MONTHS'
    | '55_MONTHS' // Used exclusively for IG promotions
    | '58_MONTHS' // Used exclusively for IG promotions
    | '59_MONTHS' // Used exclusively for IG promotions
    | '1_YEAR'
    | '2_YEAR'
    | '3_YEAR'
    | '4_YEAR'
    | '5_YEAR'
    | '6_YEAR'
    | '7_YEAR'
    | '10_YEAR'
    | 'HELOC';

export const PRODUCT_TERM_MONTH_MAP: Record<
    Exclude<ProductTerm, 'HELOC'>,
    number
> = {
    '6_MONTHS': 6,
    '55_MONTHS': 55,
    '58_MONTHS': 58,
    '59_MONTHS': 59,
    '1_YEAR': 12,
    '2_YEAR': 24,
    '3_YEAR': 36,
    '4_YEAR': 48,
    '5_YEAR': 60,
    '6_YEAR': 72,
    '7_YEAR': 84,
    '10_YEAR': 120,
};

export type ProductType = 'FIXED' | 'VARIABLE';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L741
 */
export type ProductPurpose =
    | 'OWNER_OCCUPIED'
    | 'ANY_RENTAL'
    | 'RENTAL_1_UNIT'
    | 'RENTAL_2-4_UNITS';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L643
 */
export type ProductInsurance = 'INSURED' | 'CONVENTIONAL';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L691
 */
export type ProductRentalIncompeType = 'ADDBACK' | 'OFFSET' | 'WORKSHEET';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L385
 */
export type ProductPrepaymentOption = 'STANDARD' | 'ENHANCED';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L433
 */
export type ProductRestrictionOption =
    | 'NO_RESTRICTIONS'
    | 'SOME_RESTRICTIONS'
    | 'MORE_RESTRICTIONS';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L337
 */
export type ProductHELOCOption = 'HELOC_WITH' | 'HELOC_WITHOUT';

/**
 * @ref https://github.com/nestoca/products/blob/860766bcacc3162e1818441a8927981f8db09113/api/products.go#L589
 */
export type ProductRateHold =
    | '15_DAYS'
    | '30_DAYS'
    | '45_DAYS'
    | '60_DAYS'
    | '90_DAYS'
    | '110_DAYS'
    | '120_DAYS'
    | '150_DAYS'
    | '180_DAYS';

export type EligibilityMessage = {
    key: string;
    level: EligibilityLevelType;
    /** @deprecated use `msgEn` and `msgFr` instead */
    msg: string;
    msgEn: string;
    msgFr: string;
};

export type EligibilityLevelType = 'missing' | 'critical' | 'warning';

export type ProductElegibility = {
    decision: boolean;
    messages: EligibilityMessage[];
};
