import {
    atom,
    atomFamily,
    selector,
    selectorFamily,
    useSetRecoilState,
    useRecoilState,
} from 'recoil';

import { client as apiClient } from 'libs/api';

import type { ProductElegibility } from 'types/product';
import type { Step } from 'types/product-selection';

export const currentStepId = atom<Step>({
    key: 'currentStepId',
    default: 'START',
});

export const stepProductId = atom<string | number>({
    key: 'stepProductId',
    default: '',
});

export const getProductSelectionStepValues = selectorFamily({
    key: 'GetProductSelectionStepValues',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const currentStep = get(currentStepId);

            const { data } = await apiClient.productSelectionStepInfo(
                applicationId,
                currentStep
            );

            return data;
        },
});

export const useRefreshProductSelection = (applicationId: number) => {
    const setProductSelection = useSetRecoilState(
        getProductSelection(applicationId)
    );

    const setCurrentStep = useSetRecoilState(currentStepId);

    return async () => {
        const { data } = await apiClient.productSelection(applicationId);

        setProductSelection(data);

        setCurrentStep(data.currentStepId);

        return data;
    };
};

export const useRefreshProductByStep = (applicationId: number) => {
    const setProductSelection = useSetRecoilState(
        getProductSelection(applicationId)
    );
    const [currentStep] = useRecoilState(currentStepId);

    return async () => {
        const { data } = await apiClient.productSelectionStepInfo(
            applicationId,
            currentStep
        );

        setProductSelection(data);

        return data;
    };
};

export const getProductSelection = atomFamily({
    key: 'GetProductSelection',
    default: getProductSelectionStepValues,
});

export const getProductSelectionAvailableProducts = selectorFamily({
    key: 'GetProductSelectionAvailableProducts',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const data = get(getProductSelection(applicationId));

            return data?.products || [];
        },
});

export const getProductSelectionSelectedProduct = selectorFamily({
    key: 'GetProductSelectionSelectedProduct',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const activeProductId = get(stepProductId);
            let activeProduct;

            const availableProducts = get(
                getProductSelectionAvailableProducts(applicationId)
            );
            if (activeProductId) {
                activeProduct = availableProducts.filter(
                    (product) => product.stepProductId === activeProductId
                )[0];
            }

            const selectedProduct = availableProducts.filter(
                (product) => product.selected
            )[0];

            return activeProduct ? activeProduct : selectedProduct;
        },
});

export const getProductSelectionSummaryInfo = selectorFamily({
    key: 'GetProductSelectionSummaryInfo',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const data = get(getProductSelection(applicationId));

            return data?.summary;
        },
});

export const getProductSelectionStepQuestions = selectorFamily({
    key: 'GetProductSelectionStepQuestions',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const data = get(getProductSelection(applicationId));

            return data?.questions || {};
        },
});

export const getProductSelectionStepExplinations = selectorFamily({
    key: 'GetProductSelectionStepExplinations',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const data = get(getProductSelection(applicationId));
            return data?.explanations || [];
        },
});

export const getProductSelectionSuggestedProduct = selectorFamily({
    key: 'GetProductSelectionSuggestedProduct',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const availableProducts = get(
                getProductSelectionAvailableProducts(applicationId)
            );

            return (
                availableProducts.filter((product) => product.suggested)[0] ||
                {}
            );
        },
});

export const getCurrentStep = selector({
    key: 'GetCurrentStep',
    get: ({ get }) => {
        const currentStep = get(currentStepId);

        return currentStep;
    },
});

export const productSelectionSidebarState = atom({
    key: 'productSelectionSidebarState',
    default: {
        showSidebar: false,
    },
});

export const currentElegibility = atom<ProductElegibility>({
    key: 'productElegibility',
    default: {
        decision: false,
        messages: [
            { key: '', level: 'missing', msg: '', msgEn: '', msgFr: '' },
        ],
    },
});

export const getProductEligibility = selectorFamily({
    key: 'GetProductEligibility',
    get:
        ({
            applicationId,
            productId,
            productVersion,
        }: {
            applicationId: number;
            productId: number;
            productVersion: number;
        }) =>
        async () => {
            try {
                const { data } = await apiClient.getApplicationElegibility(
                    applicationId,
                    productId,
                    productVersion
                );
                return data as ProductElegibility;
            } catch (error) {
                console.info('getProductEligibility error', error);
                return undefined;
            }
        },
});

export const getProductEligibilityState = atomFamily({
    key: 'getProductEligibilityState',
    default: getProductEligibility,
});

export const useRefreshEligibility = (
    applicationId,
    productId,
    productVersion
) => {
    const setEligibility = useSetRecoilState(
        getProductEligibilityState({ applicationId, productId, productVersion })
    );

    return async () => {
        try {
            const { data } = await apiClient.getApplicationElegibility(
                applicationId,
                productId,
                productVersion
            );
            setEligibility(data);

            return data;
        } catch (error) {
            console.info(`useRefreshEligibility error: ${error}`);
            return undefined;
        }
    };
};
