import { i18n } from '@lingui/core';
import { isNumber } from '@nestoca/ui';

import {
    ApplicationMortgage,
    SectionError,
    UpdateApplicationMortgage,
} from 'types/application';
import { PRODUCT_TERM_MONTH_MAP, ProductTerm } from 'types/product';
import { Qualification } from 'types/qualification';
import { normalizeDateToString } from 'utils';

type MortgageDetailsHasErrorsParams = {
    qualification: Qualification;
    mortgageDetails: ApplicationMortgage;
    locale: string;
};

export const mortgageDetailsHasErrors = ({
    qualification,
    mortgageDetails,
    locale,
}: MortgageDetailsHasErrorsParams): SectionError[] => {
    const mortgageDetailsQualificationState =
        qualification?.sectionStates?.mortgage;

    const mortgageDetailsFieldNameMap: Record<string, string> = {
        amortizationMonths: 'mortgageDetails.amortizationMonths',
        amortizationYears: 'mortgageDetails.amortizationYears',
        mortgageAmount: 'mortgageAmount',
    };

    const errors: SectionError[] = [];

    // Get qualification discrepancies
    if (
        mortgageDetailsQualificationState.invalid &&
        mortgageDetailsQualificationState.invalidFields
    ) {
        // Map field names to readable labels
        const humanReadableInvalidFields =
            mortgageDetailsQualificationState.invalidFields.map((fieldName) => {
                const readableFieldName =
                    mortgageDetailsFieldNameMap[fieldName];

                return readableFieldName
                    ? i18n._(readableFieldName, { locale })
                    : fieldName;
            });

        errors.push({
            title: 'discrepancyBanner.title',
            body: 'discrepancyBanner.error',
            fields: mortgageDetailsQualificationState.invalidFields,
            values: {
                numberOfDiscrepancies: humanReadableInvalidFields.length,
                missingFields: humanReadableInvalidFields.join(', '),
            },
        });
    }

    // Qualification mortgageAmount will be null unless overridden
    const mortgageAmountDoesNotMatchQualification =
        isNumber(qualification?.mortgageAmount) &&
        isNumber(mortgageDetails?.mortgageAmount) &&
        mortgageDetails?.mortgageAmount !== qualification?.mortgageAmount;

    // Check mortgage amount discrepancy
    if (mortgageAmountDoesNotMatchQualification) {
        errors.push({
            title: 'discrepancyBanner.title',
            body: 'mortgageDetails.discrepancy.error',
        });
    }

    return errors;
};

export const getMaturityDateOverride = (
    enableMaturityDateOverride: boolean,
    mortgageDetails: ApplicationMortgage,
    formValues: Partial<UpdateApplicationMortgage>
) => {
    const {
        maturityDate: existingMaturityDate,
        maturityDateOverride: existingMaturityDateOverride,
    } = mortgageDetails || {};
    const { maturityDateOverride: formMaturityDateOverride } = formValues || {};

    // if enableMaturityDateOverride is disabled, return the existing maturityDateOverride
    if (!enableMaturityDateOverride) {
        return existingMaturityDateOverride;
    }

    const normalizedFormMaturityDateOverride = normalizeDateToString(
        formMaturityDateOverride
    );

    // if maturityDateOverride was already saved and the form value hasn't changed, return the override
    if (
        !!existingMaturityDateOverride &&
        normalizedFormMaturityDateOverride === existingMaturityDateOverride
    ) {
        return existingMaturityDateOverride;
    }

    // If the edited maturityDateOverride is the same as the maturityDate, don't return an override
    if (
        !!existingMaturityDate &&
        existingMaturityDate === normalizedFormMaturityDateOverride
    ) {
        return null;
    }

    return normalizedFormMaturityDateOverride;
};

export const getProductTermMonthsOverride = (
    termOverride: number,
    productTerm: ProductTerm
) => {
    const mappedProductTermMonths = PRODUCT_TERM_MONTH_MAP[productTerm];
    const overrideMatchesProductTerm = mappedProductTermMonths === termOverride;

    if (overrideMatchesProductTerm) return null;

    return termOverride;
};

export const getRateLockOverride = (
    newDate?: string | null,
    existingDate?: string | null,
    override?: string | null
) => {
    // Form sends undefined when not editing, so we return existing override
    if (newDate === undefined) return override;

    // If an override exists or a new date differs from the existing date, return the new date
    if (override || newDate !== existingDate) {
        return normalizeDateToString(newDate);
    }

    return override;
};
